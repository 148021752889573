// MainWrapper.js
import React, { useContext, Suspense } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import routes from "routes";
import Loader from 'components/loader';
import { useAbility } from 'utils/Contexts/AbilityContext';
import { useSelector } from 'react-redux';
import ViewDetails from "views/admin/ViewDetails"
import AddTransaction from "views/admin/AddTransaction"
function MainWrapper() {
    const ability = useAbility();
    const navigate = useNavigate();

    const perMission = useSelector((state) => state.auth.access);
    const isAdmin = useSelector((state) => state.auth.isAdmin);

    const access_tken = localStorage.getItem('_authData');
    
    return (
        <div className=''>

            <Routes>

                {routes.map((route, index) => {
                    // Find the ability that matches the route's moduleId
                    console.log(ability,"ability");
                    if (isAdmin === false || isAdmin === "false") {
                        const matchingAbility = ability?.m?.find((a) => a?.subject == route?.check);
                        if (matchingAbility || route?.id == 1 || route?.id == 10) {
                            
                            return (
                                <Route
                                    key={route.path} // You can use a more unique key here
                                    path={route.path}
                                    element={
                                        <Suspense fallback={<Loader loading={true} />}>
                                            <route.component />
                                        </Suspense>
                                    }
                                />
                            );
                        }
                        else if(route?.id == 10)
                            {
                                if(access_tken)
                                    {
                                        <Route
                                        key={route.path} // You can use a more unique key here
                                        path={route.path}
                                        element={
                                            <Suspense fallback={<Loader loading={true} />}>
                                                <route.component />
                                            </Suspense>
                                        }
                                    />
                                    }
                                    else{
                                        localStorage.clear();
                                        navigate('/login')
                                    }
                            }


                    }
                    else {
                        return (
                            <Route
                                key={route.path} // You can use a more unique key here
                                path={route.path}
                                element={<route.component />}
                            />
                        );
                    }


                })}
                <Route path="/rider_details/:id" element={<ViewDetails />} />
                <Route path="/tesrt" element={<AddTransaction />} />
            </Routes>
        </div>

    );
}

export default MainWrapper;