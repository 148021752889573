import React, { useEffect } from 'react';
import { useTable,useGlobalFilter  } from 'react-table';

const DefaultTable = ({ columns, data, searchVal, checkboxColumns, onCellValueChange, isEditable }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { globalFilter },
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            manualPagination: true
        },
        useGlobalFilter
    );

    useEffect(() => {
        setGlobalFilter(searchVal);
      }, [searchVal]);

    return (
        <div>
            <table {...getTableProps()} className="w-100">
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} className="hover-row">
                                {row.cells.map((cell, index) => (
                                    <td {...cell.getCellProps()}>

                                        {isEditable ? (
                                            checkboxColumns?.includes(cell.column.id) ? (
                                                <input
                                                    type="checkbox"
                                                    checked={row.original[cell.column.id]}
                                                    onChange={() => {
                                                        onCellValueChange(row.original.masterRaciId, cell.column.id, !row.original[cell.column.id]);
                                                    }}
                                                />
                                            ) : (
                                                cell.render('Cell')
                                            )
                                        ) : (
                                            <div {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </div>
                                        )}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default DefaultTable;