import React, { useContext, useEffect, useMemo, useState } from 'react';
import FormControl from "components/controls/FormControl";
import CustomTable from "components/Table"
import Button from "components/buttons/ADD";
import EditDelete from "components/buttons/EditDelete";
import { ModalContext } from 'utils/Contexts/modalContext';
import CreateUser from 'components/modals/CreateUser';
import EditUser from 'components/modals/EditUser';
import CoomonDelete from 'components/modals/CoomonDelete';
import Verification from 'assets/img/dashboards/check_circle.svg';
import error from 'assets/img/dashboards/error.svg';
import Loader from 'components/loader';
import { DELETEDOCKYARDAPI, VIEWCHANNELFLEETSTATUS, VIEWCREATECHANNELPARTNERAPI } from 'utils/Services';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
function FleetStatus() {
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const [loading, setLoading] = useState(false)
    const [allData, setallData] = useState([])
    const [options, setOptions] = useState([])
    const [filteredData, setFilteredData] = useState([]); 
    const [filterStatus, setFilterStatus] = useState(''); 
    const [showDropdown, setShowDropdown] = useState(false);
    const [filterStatuscountry, setFilterStatuscountry] = useState(''); 
    const [showDropdowncountry, setShowDropdowncountry] = useState(false);


    const navigate = useNavigate();
    const handlechangebtn = () => {
        setShowDropdown(!showDropdown);
    }

const handlechangebtncountry = () =>{
    setShowDropdowncountry(!showDropdowncountry);

}

    const handleFilterChange = (e) => {
        setFilterStatus(e.target.value);
        setShowDropdown(false); // Hide dropdown after selection
    };

    const handleFilterChangecontry = (e) => {
        setFilterStatuscountry(e.target.value);
        setShowDropdowncountry(false); // Hide dropdown after selection
    };

    const FeedsColumns = useMemo(
        () => [
            {
                Header: "Sno.",
                accessor: (row, index) => {
                    return (
                        <>
                            <span className='text-sm'>{index + 1}</span>
                        </>
                    );
                }
            },
            {
                Header: "Vehical ID",
                accessor: (Customer) => Customer?.full_name || "" // Use conditional rendering
            },
            // pending
            {
                Header: "Rider Name", // The header text for this column is "Associated With"
                accessor: (Customer) => {
                    if (Customer?.channel_partner == 1) {
                        return "Other";
                    } else if (Customer?.channel_partner == 2) {
                        return "Zeway Rider";
                    } else {
                        return "Channel Partner"; // or any default value you prefer
                    }
                }
            },
            {
                id: "Live Status", 
                Header: () => (
                    <div className="user-status-container" style={{ position: 'relative' }} 
                    onClick={handlechangebtn}
                    >
                    <div className="d-flex align-items-center">
                        <span>User Status</span>
                        <span
                            className="arrow-icon ml-2"
                            style={{ cursor: 'pointer' }}
                        >
                            ▼
                        </span>
                    </div>
                    {showDropdown && (
                        <div
                            className="dropdown-container"
                            style={{
                                position: 'absolute',
                                top: '100%',
                                left: 0,
                                zIndex: 1,
                                background: 'white',
                                borderRadius: '4px'
                            }}
                        >
                            <select
                                className="form-control"
                                value={filterStatus}
                                onChange={handleFilterChange}
                            >
                                <option value="">All</option>
                                <option value="Active">Running</option>
                                <option value="Inactive">Not Running</option>
                            </select>
                        </div>
                    )}
                </div>
                
                ),
                accessor: (row) => {
                    return (
                        <div className='d-flex align-items-center'>
                            <div>
                                {row?.is_identificationCard && row?.is_aadhaarCard ? (
                                    <span className='Verified'>Running</span>
                                ) : (
                                    <span className='error'>Not Running</span>
                                )}
                            </div>
                        </div>
                    );
                }
            },
            {
                id: "City", 
                Header: () => (
                    <div className="user-status-container" style={{ position: 'relative' }} 
                    onClick={handlechangebtncountry}
                    >
                    <div className="d-flex align-items-center">
                        <span>City</span>
                        <span
                            className="arrow-icon ml-2"
                            style={{ cursor: 'pointer' }}
                        >
                            ▼
                        </span>
                    </div>
                    {showDropdowncountry && (
                        <div
                            className="dropdown-container"
                            style={{
                                position: 'absolute',
                                top: '100%',
                                left: 0,
                                zIndex: 1,
                                background: 'white',
                                borderRadius: '4px'
                            }}
                        >
                            <select
                                className="form-control"
                                value={filterStatuscountry}
                                onChange={handleFilterChangecontry}
                            >
                                <option value="">All</option>
                                <option value="Active">Noida</option>
                                <option value="Inactive">Lucknow</option>
                            </select>
                        </div>
                    )}
                </div>
                
                ),
                accessor: (row) => {
                    return (
                        <div className='d-flex align-items-center'>
                            <div>
                                {row?.is_identificationCard && row?.is_aadhaarCard ? (
                                    <span className=''>Noida</span>
                                ) : (
                                    <span className=''>Lucknow</span>
                                )}
                            </div>
                        </div>
                    );
                }
            },
         

            {
                Header: "Actions", // The header text for this column is "Service"
                accessor: (row, index) => {
                    return (
                        <div className='d-flex justify-content-between '>

                            <div className='w-100 mx-2' onClick={() => handleDetails(row?.id)}>
                                <EditDelete text="View all Status" type="Button" />



                            </div>
                      
                        </div>




                    );
                }
            },
        ],
        [options?.length > 0 ,showDropdown,showDropdowncountry]
    );



    const channelid = localStorage.getItem('channelId');

    const fecthData = async () => {
        try {
            setLoading(true)
            const result = await VIEWCHANNELFLEETSTATUS(`?channel_partner=${channelid}`);
            if (result?.res?.status == 200) {
                console.log(result?.res?.data,'result?.res?.data');
                setallData(result?.res?.data)
                filterData(result?.res?.data, filterStatus)
                filterData(result?.res?.data, filterStatuscountry)
                setLoading(false)
            }
            else {
                setLoading(false)
            }

        }
        catch (err) {
            console.log(err)
            setLoading(false)
        }

    }

    const filterData = (data, status) => {
        let filtered = data;
        if (status === "Active") {
            filtered = data.filter((user) => user?.is_identificationCard && user?.is_aadhaarCard);
        } else if (status === "Inactive") {
            filtered = data.filter((user) => !(user?.is_identificationCard && user?.is_aadhaarCard));
        }
        setFilteredData(filtered);
    };
    useEffect(() => {
        setFilteredData(allData);
    }, [allData]);
    useEffect(() => {
        const filterData = () => {
            let filtered = allData;
    
            // Filter by filterStatus
            if (filterStatus) {
                filtered = filtered.filter((user) => {
                    const isActive = user?.is_identificationCard && user?.is_aadhaarCard;
                    return filterStatus === "Active" ? isActive : !isActive;
                });
            }
    
            // Filter by filterStatuscountry
            if (filterStatuscountry) {
                filtered = filtered.filter((user) => {
                    const isActive = user?.is_identificationCard && user?.is_aadhaarCard;
                    return filterStatuscountry === "Active" ? isActive : !isActive;
                });
            }
    
            setFilteredData(filtered);
        };
    
        filterData();
    }, [filterStatus, filterStatuscountry, allData]);
    
    

    useEffect(() => {
        if (options && options.length > 0) {
            fecthData()

        }

    }, [options]);
    useEffect(() => {

        fetchChannel()
    }, []);

    useEffect(() => {
        filterData(allData, filterStatus, filterStatuscountry);
    }, [filterStatus, filterStatuscountry, allData]); // Refetch data when filterStatus or filterStatuscountry changes
    
    const handleStatusChange = (e) => {
        setFilterStatus(e.target.value);
    };

    const handleDelete = async (data) => {
        setLoading(true)
        try {
            const result = await DELETEDOCKYARDAPI(data);

            if (result?.res?.status == 200) {
                setLoading(false)
                toast.success(result?.res?.message)
                fecthData(); closeModal();
            }
        }
        catch (err) {
            toast.error(err?.message || err)
            setLoading(false)
            closeModal();
        }


    }
    const fetchChannel = async () => {
        setLoading(true);
        try {
            const result = await VIEWCREATECHANNELPARTNERAPI();

            if (result?.res?.status === 200) {
                const data = result?.res?.data?.map((res) => ({
                    id: res?.id,
                    label: res?.channel_partner_name,
                    value: res?.channel_partner_name
                }));

                setOptions(data);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const handle = (data, info) => {
        const CreateDocumnet = <CreateUser closeModal={closeModal} reload={fecthData} />;
        const EditDocumnet = <EditUser closeModal={closeModal} data={info} reload={fecthData} />;
        const DelteDocumnet = <CoomonDelete closeModal={closeModal} data={info} handle={handleDelete} reload={fecthData} />
        switch (data) {
            case "create":
                handleModalData(CreateDocumnet, "md");
                break;
            case "edit":
                handleModalData(EditDocumnet, "md");
                break;
            case "delete":
                handleModalData(DelteDocumnet, "md");
                break;

        }

    }

    const handleDetails = (id) => {

        navigate(`/fleet_stats`)
    }

    return (
        <div className="container-fluid pb-5">
            <div className='row'>
                <Loader loading={loading} />
                <div className='d-flex align-items-center justify-content-between my-3'>
                   <div className='col-lg-4'>
                        <FormControl control={"Search"}  />


                    </div>
                    {/* <div onClick={() => handle("create")}>
                        <Button text="Create Users" />
                    </div> */}
                    <div>Total Running Rider : 28</div>
                </div>
                
                {filteredData.length > 0 && (
                    <div className='default_table'>
                        <CustomTable data={filteredData} columns={FeedsColumns} />
                    </div>
                )}
            </div>
        </div>
    )
}



export default FleetStatus