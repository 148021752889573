import React, { useContext, useEffect, useMemo, useState } from 'react';
import FormControl from "components/controls/FormControl";
import CustomTable from "components/Table"
import Button from "components/buttons/ADD";
import EditDelete from "components/buttons/EditDelete";
import { ModalContext } from 'utils/Contexts/modalContext';
import CreatChannelPartner from 'components/modals/CreatChannelPartner';
import EditChannelPartner from 'components/modals/EditChannelPartner';
import CoomonDelete from 'components/modals/CoomonDelete';
import Loader from 'components/loader';
import FallBack from "components/fallBack"
import { USERViEWAPI, VIEWCREATECHANNELPARTNERAPI, GETSTATEAPI, GETCITYAPI } from 'utils/Services';
import { toast } from 'react-toastify';


function Genral(data) {
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const [loading, setLoading] = useState(false)
    const [allData, setallData] = useState([])
    const [options, setOptions] = useState([])
    const fecthData = async () => {
        try {
            setLoading(true)
            const result = await USERViEWAPI(data.data);
            if (result?.res?.status == 200) {
                setallData(result?.res?.data)
                setLoading(false)
            }
            else {
                setLoading(false)
            }

        }
        catch (err) {
            console.log(err)
            setLoading(false)
        }

    }
    useEffect(() => {
        fecthData()
    }, [data]);
    const fetchChannel = async () => {
        setLoading(true);
        try {
            const result = await VIEWCREATECHANNELPARTNERAPI();

            if (result?.res?.status === 200) {
                const data = result?.res?.data?.map((res) => ({
                    id: res?.id,
                    label: res?.channel_partner_name,
                    value: res?.channel_partner_name
                }));

                setOptions((res) => ({
                    ...res,
                    channel: data
                }));
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }
    const fetchState = async () => {
        setLoading(true)
        try {
            const result = await GETSTATEAPI();
            if (result?.res?.status == 200) {
                const data = result?.res?.data?.map((res) => ({
                    id: res?.id,
                    label: res?.name,
                    value: res?.name
                }));

                setOptions((res) =>
                ({
                    ...res,
                    state: data
                }))
                setLoading(false)
            }
            else {
                setLoading(false)
            }
        }
        catch (err) {
            console.log(err);
            setLoading(false)

        }



    }
    const fetchCity = async () => {

        if (allData?.state !== '' && allData?.state !== null && allData?.state !== undefined) {
            const data = {
                "state_id": allData?.state
            };
            setOptions((res) => ({
                ...res,
                city: []
            }));
            try {
                setLoading(true);
                const result = await GETCITYAPI(data);

                if (result?.status === 200) {
                    const data = result?.data?.map((res) => ({
                        id: res?.id,
                        label: res?.name,
                        value: res?.name
                    }));

                    setOptions((res) => ({
                        ...res,
                        city: data
                    }));
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        }


    }
    useEffect(() => {
        fetchState()
    }, [])
    useEffect(() => {

        fetchChannel()
    }, []);
    useEffect(() => {
        fetchCity()
    }, [allData?.state])

    return (
        <div className="container-fluid pb-5">
            <div className='row'>
                <Loader loading={loading} />
                {options && options.channel?.length > 0 && (
                    <div className='my-5'>
                        <div className='details_tile'>
                            <div className='d-flex justify-content-between'>
                                <div className='col-lg-6 text-center'>
                                    Full Name
                                </div>
                                <div className='col-lg-6 text-center'>
                                    {allData?.full_name}
                                </div>
                            </div>
                        </div>

                        <div className='details_tile'>
                            <div className='d-flex justify-content-between'>
                                <div className='col-lg-6 text-center'>
                                    Associated With
                                </div>
                                <div className='col-lg-6 text-center'>
                                    {allData?.channel_partner == 1 ? "Other" :
                                        allData?.channel_partner == 2 ? "Zeway Rider" :
                                            "Channel Partner"}
                                </div>
                            </div>
                        </div>

                        <div className='details_tile'>
                            <div className='d-flex justify-content-between'>
                                <div className='col-lg-6 text-center'>
                                    Channel Partner Name
                                </div>
                                <div className='col-lg-6 text-center'>
                                    {(() => {
                                        if (allData?.channel_partner != 1 && allData?.channel_partner != 2) {
                                            const filte_data = options?.channel?.filter((res) => res?.id == allData?.channel_partner);
                                            return filte_data?.length > 0 ? filte_data[0]?.label : "NA";
                                        } else {
                                            return "NA";
                                        }
                                    })()}
                                </div>
                            </div>
                        </div>

                        <div className='details_tile'>
                            <div className='d-flex justify-content-between'>
                                <div className='col-lg-6 text-center'>
                                    Contact Number
                                </div>
                                <div className='col-lg-6 text-center'>
                                    {allData?.contact_no}
                                </div>
                            </div>
                        </div>

                        <div className='details_tile'>
                            <div className='d-flex justify-content-between'>
                                <div className='col-lg-6 text-center'>
                                    Email ID
                                </div>
                                <div className='col-lg-6 text-center'>
                                    {allData?.email}
                                </div>
                            </div>
                        </div>

                        <div className='details_tile'>
                            <div className='d-flex justify-content-between'>
                                <div className='col-lg-6 text-center'>
                                    Address
                                </div>
                                <div className='col-lg-6 text-center'>
                                    {allData?.address_line_1}
                                </div>
                            </div>
                        </div>

                        <div className='details_tile'>
                            <div className='d-flex justify-content-between'>
                                <div className='col-lg-6 text-center'>
                                    State
                                </div>
                                <div className='col-lg-6 text-center'>
                                    {(() => {
                                        const filte_data = options?.state?.filter((res) => res?.id == allData?.state);
                                        return filte_data?.length > 0 ? filte_data[0]?.label : "NA";
                                    })()}
                                </div>
                            </div>
                        </div>

                        <div className='details_tile'>
                            <div className='d-flex justify-content-between'>
                                <div className='col-lg-6 text-center'>
                                    City
                                </div>
                                <div className='col-lg-6 text-center'>
                                    {(() => {
                                        const filte_data = options?.city?.filter((res) => res?.id == allData?.city);
                                        return filte_data?.length > 0 ? filte_data[0]?.label : "NA";
                                    })()}
                                </div>
                            </div>
                        </div>

                        <div className='details_tile'>
                            <div className='d-flex justify-content-between'>
                                <div className='col-lg-6 text-center'>
                                    Pincode
                                </div>
                                <div className='col-lg-6 text-center'>
                                    {allData?.pincode}
                                </div>
                            </div>
                        </div>

                        <div className='details_tile'>
                            <div className='d-flex justify-content-between'>
                                <div className='col-lg-6 text-center'>
                                    Gender
                                </div>
                                <div className='col-lg-6 text-center'>
                                    {(() => {
                                        if (allData?.gender == 1) {
                                            return "Male";
                                        } else if (allData?.gender == 2) {
                                            return "Female";
                                        } else {
                                            return "Other";
                                        }
                                    })()}
                                </div>
                            </div>
                        </div>

                        <div className='details_tile'>
                            <div className='d-flex justify-content-between'>
                                <div className='col-lg-6 text-center'>
                                    DOB
                                </div>
                                <div className='col-lg-6 text-center'>
                                    {allData?.date_birth}
                                </div>
                            </div>
                        </div>
                    </div>
                )}






            </div>
        </div>
    )
}

export default Genral