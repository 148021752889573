import React, { useContext, useEffect, useMemo, useState } from 'react';
import FormControl from "components/controls/FormControl";
import CustomTable from "components/Table"
import Button from "components/buttons/ADD";
import EditDelete from "components/buttons/EditDelete";
import { ModalContext } from 'utils/Contexts/modalContext';
import CreateUsergenerate from 'components/modals/CreateUsergenerate';
import EditUser from 'components/modals/EditUser';
import CoomonDelete from 'components/modals/CoomonDelete';
import Verification from 'assets/img/dashboards/check_circle.svg';
import error from 'assets/img/dashboards/error.svg';
import Loader from 'components/loader';
import { DELETEDOCKYARDAPI, VIEWUSEREAPI, VIEWCREATECHANNELPARTNERAPI } from 'utils/Services';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
function Reportsstatus() {
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const [loading, setLoading] = useState(false)
    const [allData, setallData] = useState([])
    const [options, setOptions] = useState([])
    const [filteredData, setFilteredData] = useState([]);


    const navigate = useNavigate();

    const FeedsColumns = useMemo(
        () => [
            {
                Header: "Sno.",
                accessor: (row, index) => {
                    return (
                        <>
                            <span className='text-sm'>{index + 1}</span>
                        </>
                    );
                }
            },
            {
                Header: "Report Name",
                accessor: (Customer) => Customer?.full_name || "" // Use conditional rendering
            },
            // pending
            
            {
                Header: "Start Date", // The header text for this column is "Associated With"
                accessor: (Customer) => {
                    if (Customer?.channel_partner == 1) {
                        return "Other";
                    } else if (Customer?.channel_partner == 2) {
                        return "Zeway Rider";
                    } else {
                        return "Channel Partner"; // or any default value you prefer
                    }
                }
            },

            {
                Header: "End Date", // The header text for this column is "Associated With"
                accessor: (Customer) => {
                    if (Customer?.channel_partner == 1) {
                        return "Other";
                    } else if (Customer?.channel_partner == 2) {
                        return "Zeway Rider";
                    } else {
                        return "Channel Partner"; // or any default value you prefer
                    }
                }
            },


            {
                Header: "Actions", // The header text for this column is "Service"
                accessor: (row, index) => {
                    return (
                        <div className='d-flex justify-content-start '>
                            <div className='flex'>
                                <div className='w-100 mx-2' onClick={() => handleDetails(row?.id)}>
                                    <EditDelete text="Download PDF" type="Button" />



                                </div>
                            </div>
                        </div>




                    );
                }
            },
        ],
        [options?.length > 0]
    );
    const fecthData = async () => {
        try {
            setLoading(true)
            const result = await VIEWUSEREAPI();
            if (result?.res?.status == 200) {
                setallData(result?.res?.data)
                setLoading(false)
            }
            else {
                setLoading(false)
            }

        }
        catch (err) {
            console.log(err)
            setLoading(false)
        }

    }


    useEffect(() => {
        setFilteredData(allData);
    }, [allData]);




    useEffect(() => {
        if (options && options.length > 0) {
            fecthData()

        }

    }, [options]);
    useEffect(() => {

        fetchChannel()
    }, []);


    const handleDelete = async (data) => {
        setLoading(true)
        try {
            const result = await DELETEDOCKYARDAPI(data);

            if (result?.res?.status == 200) {
                setLoading(false)
                toast.success(result?.res?.message)
                fecthData(); closeModal();
            }
        }
        catch (err) {
            toast.error(err?.message || err)
            setLoading(false)
            closeModal();
        }


    }
    const fetchChannel = async () => {
        setLoading(true);
        try {
            const result = await VIEWCREATECHANNELPARTNERAPI();

            if (result?.res?.status === 200) {
                const data = result?.res?.data?.map((res) => ({
                    id: res?.id,
                    label: res?.channel_partner_name,
                    value: res?.channel_partner_name
                }));

                setOptions(data);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const handle = (data, info) => {
        const CreateDocumnet = <CreateUsergenerate closeModal={closeModal} />;
       
                handleModalData(CreateDocumnet, "md");
        
    }



    const handleDetails = (id) => {

        // navigate(`/rider_details/${id}`)
    }

    return (
        <div className="container-fluid pb-5">
            <div className='row'>
                <Loader loading={loading} />
                <div className='d-flex justify-content-between my-3'>
                    <div className='col-lg-4'>
                        <FormControl control={"Search"} />


                    </div>
                    <div onClick={() => handle("create")}>
                        <Button text="Generate New Report" />
                    </div>
                </div>

                <div className='d-flex align-items-center'>
                    
                </div>

                {filteredData.length > 0 && (
                    <div className='default_table'>
                        <CustomTable data={filteredData} columns={FeedsColumns} />
                    </div>
                )}
            </div>
        </div>
    )
}




export default Reportsstatus