import React, { useEffect, useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';  // Import Formik and Form
import FormControl from 'components/controls/FormControl';
import Loader from "components/loader"
import Button from 'components/buttons/ADD';
import ADD from "assets/img/dashboards/add_circle.svg";
import Delete from "assets/img/dashboards/delete.svg"
import { PLANDELETEEDITAPI, PLANUPDATEAPI, CREATESUBSCRIPTIONAPI } from 'utils/Services';
import * as yup from 'yup';
import { toast } from 'react-toastify';




function Subscription({ closeModal, reload, data }) {

    const [allData, setallData] = useState({})
    const [options, setOptions] = useState({
        state: [], city: [], status: [{
            id: true,
            label: 'Active',
            value: true
        }, {
            id: false,
            label: 'InActive',
            value: false
        }]
    });

    const [loading, setLoading] = useState(false)
    const [selectState, setSelectState] = useState()
    const subscriptionSchema = yup.object().shape({
        total_days: yup.number()
            .required('Number of days is required')
            .min(1, 'Number of days must be at least 1')
            .max(365, 'Number of days cannot exceed 365'),
        amount: yup.number().required('Amount is required').min(1, 'amount must be at least 1')
    });
    const validationSchema = yup.object().shape({
        formData: yup.object().shape({
            plan_name: yup.string()
                .required('Plan name is required')
                .min(3, 'Plan name must be at least 3 characters'),
            feature: yup.string().required('Description  is required'),
            securityDeposit: yup.number().required('Security deposit is required').positive('Security deposit must be a positive number').integer('Security deposit must be an integer'),
            subscription: yup.array().of(subscriptionSchema),
            range: yup.number()
                .typeError('Range must be a number')
                .required('Range is required')
                .min(1, 'Range must be at least 1'),
  

                tenure: yup.number()
                .typeError('Tenure must be a number')
                .required('Tenure is required')
                .min(1, 'Tenure must be at least 1'),
            max_speed: yup.number()
                .typeError('Max Speed must be a number')
                .required('Max Speed is required')
                .min(1, 'Max Speed must be at least 1'),
       
                tenure: yup.number()
                .typeError('Tenure must be a number')
                .required('Tenure is required')
                .min(1, 'Tenure must be at least 1'),
            is_active: yup.boolean().required(' Status is required'),
        }),
    });
    const handle = async (values) => {
        const { subscription, label, ...formDataWithoutSubscription } = values.formData;
        const _data =
        {
            plan_detail: formDataWithoutSubscription, subscription: values.formData.subscription
        }
        try {
            const result = await PLANUPDATEAPI(data, _data);

            if (result?.status === 201) {
                toast.success(result.message);
                closeModal()
                { reload && reload() }
            }
            else {
                toast.error(result.message);
                closeModal()
            }
        }
        catch (err) {
            toast.error(err.message);

        }
    }

    const fecthData = async () => {
        try {
            const result = await PLANDELETEEDITAPI(data);
            if (result?.res?.status == 200) {
                setallData(result?.res?.data)
            } else {
                toast.error(result?.res?.message)
            }

        }
        catch (err) {
            toast.error(err || err.message)
        }

    }
    useEffect(() => {
        fecthData();
    }, [])
console.log(allData?.tenure,"allData?.tenure")
    return (
        <div className='row'>
            <Loader loading={loading} />
            <div className='com_modal'>
                {/* Use Formik wrapper */}
                <Formik
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    initialValues={{
                        formData: {
                            "planType": "Subscription",
                            "plan_name": allData?.plan_detail?.plan_name ? allData?.plan_detail?.plan_name : '',
                            "feature": allData?.plan_detail?.feature ? allData?.plan_detail?.feature : "",
                            "securityDeposit": allData?.plan_detail?.securityDeposit ? allData?.plan_detail?.securityDeposit : "",
                            "range": allData?.plan_detail?.range ? allData?.plan_detail?.range : "",
                            "max_speed": allData?.plan_detail?.max_speed ? allData?.plan_detail?.max_speed : " ",
                            "is_active": allData?.plan_detail?.is_active,
                            'subscription': allData?.subscription ? allData?.subscription : "",
                            'tenure': allData?.plan_detail?.tenure ? allData?.plan_detail?.tenure : "",

                        },
                    }}

                    onSubmit={handle}

                >
                    {({ values, handleSubmit, setFieldValue, formik }) => (
                        // Wrap your content in the Form component
                        <Form className='form_default text_left'>
                            <div className='mb-3'>
                                <FormControl control="FormHead" title="Update Vehicle Category" />
                            </div>
                            <div className='mb-3'>
                                <div className='row g-3'>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="SelectBox"
                                            name={"formData.is_active"}
                                            label={"Vehicle Category Status"}
                                            options={options.status}
                                            prevalue={values.formData.is_active}
                                            required={true}




                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.plan_name"}
                                            type={"text"}
                                            label={"Vehicle Category Name"}
                                            required={true}

                                        />
                                    </div>

                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.range"}
                                            type={"number"}
                                            label={"Range (Km)"}
                                            required={true}

                                        />
                                    </div>

                                    {
                                        values?.formData?.feature && <div className='col-lg-6'>
                                            <FormControl
                                                control="Editor"
                                                name={"formData.feature"}
                                                type={"text"}
                                                label={"Feature (Tag field)"}
                                                value={values?.formData?.feature}
                                                required={true}
                                            />
                                        </div>
                                    }

                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.max_speed"}
                                            type={"number"}
                                            label={"Max. Speed (kmph)"}
                                            required={true}

                                        />
                                    </div>

                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.tenure"}
                                            type={"text"}
                                            label={"Tenure"}
                                            required={true}

                                        />
                                    </div>

                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.label"}
                                            type={"hidden"}
                                            label={"Amount"}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                    </div>
                                    {
                                        values.formData?.subscription && <div className='col-lg-12'>
                                            <FieldArray name="formData.subscription">
                                                {({ push, remove }) => (
                                                    <div>
                                                        <div className='d-flex justify-content-end align-items-center'>
                                                            <div
                                                                className="icon_dash"
                                                                onClick={() => {
                                                                    push({ total_days: '', amount: '' }); // push an empty object when adding new subscription
                                                                }}
                                                            >
                                                                <img src={ADD} alt="Add" />
                                                            </div>
                                                        </div>
                                                        {values?.formData?.subscription?.map((subscription, index) => (
                                                            <div key={index} className="d-flex justify-content-between align-items-center mb-2">
                                                                <div className='col-lg-5'>
                                                                    <FormControl
                                                                        control="InputLabel"
                                                                        label="No of Days "
                                                                        type="number"
                                                                        name={`formData.subscription.${index}.total_days`}
                                                                        required={true}

                                                                    />
                                                                </div>
                                                                <div className='col-lg-5'>
                                                                    <FormControl
                                                                        control="InputLabel"
                                                                        label="Amount (In Rupees) "
                                                                        type="number"
                                                                        name={`formData.subscription.${index}.amount`}
                                                                        required={true}

                                                                    />
                                                                </div>

                                                                {
                                                                    index > 0 && <div
                                                                        className="icon_dash cursor-pointer"
                                                                        onClick={() => remove(index)}
                                                                    >
                                                                        <img src={Delete} alt="Delete" />
                                                                    </div>
                                                                }

                                                                {
                                                                    index == 0 &&
                                                                    <div

                                                                    >

                                                                    </div>
                                                                }

                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </FieldArray>
                                        </div>
                                    }


                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="Checkbox"
                                            name={"formData.discount"}
                                            label={"Enable Discount Code"}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.label3"}
                                            type={"text"}
                                            label={"Discount Code"}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.securityDeposit"}
                                            type={"number"}
                                            label={"Security Deposit (In Rupees)"}
                                            required={true}

                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.label"}
                                            type={"hidden"}
                                            label={"Notification Thersold"}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                    </div>

                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.label1"}
                                            type={"text"}
                                            label={"Balance"}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.label2"}
                                            type={"text"}
                                            label={"Kilometers Left"}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.label3"}
                                            type={"text"}
                                            label={"Time Left"}
                                        />
                                    </div>

                                    <div className='col-lg-6'>
                                    </div>
                                </div>

                            </div>

                            <div className='d-flex justify-content-end'>
                                <div className='col-lg-2 mx-5' onClick={closeModal}>
                                    <Button text="Cancel" type="button" isCancel={true} />
                                </div>

                                <div className='col-lg-2'>
                                    <Button text="Save" type="submit" />
                                </div>

                            </div>
                            <div>
                                <p>
                                    all asterisk (*) field are required
                                </p>
                            </div>

                        </Form>

                    )}
                </Formik>
            </div>
        </div >
    );
}

export default Subscription;
