import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik"; // Import Formik and Form
import FormControl from "components/controls/FormControl";
import Loader from "components/loader";
import Button from "components/buttons/ADD";
import { FLEETCREATEAPI, FLEETUPDATEAPI } from "utils/Services";
import * as Yup from "yup";
import { toast } from "react-toastify";

function SosDetails({
  nextStep,
  setDetails,
  closeModal,
  details,
  id,
  reload,
  prevStep,
}) {
  const [loading, setLoading] = useState(false);
  const [selectState, setSelectState] = useState();

  const validationSchema = Yup.object().shape({
    formData: Yup.object().shape({
      device_serial_no: Yup.string().nullable(),
      iot_no: Yup.string().required("IMEI Number is required"),
      // fleet_no:Yup.number().required("fleet Number is required"),
      sos_text: Yup.string().nullable(),
      network_operator: Yup.string()
        .required("Network Operator is required")
        .test(
          "no-leading-space",
          "Network Operator cannot start with a space",
          (value) => value && value.trim().length > 0 && value[0] !== " "
        ),
    }),
  });
  
  const handle = async (values) => {
    setLoading(true);

    const new_result = {
      ...details.step0,
      ...details.step1,
      ...details.step2,
      ...values.formData,
    };

    try {
      if (id) {
        const result = await FLEETUPDATEAPI(id, new_result);

        if (result?.res?.status == 200) {
          setLoading(false);
          toast.success(result?.res?.message);
          closeModal();
          reload();
        } else {
          setLoading(false);
          toast.error(result?.message);
          closeModal();
        }
      } else {
        const result = await FLEETCREATEAPI(new_result);
        if (result?.status == 201) {
          setLoading(false);
          toast.success(result?.message);
          closeModal();
          reload();
        } else {
          setLoading(false);
          closeModal();
          toast.error(result?.message);
        }
      }
    } catch (err) {
      setLoading(false);
      toast.error(err?.message);
      closeModal();
    }
  };

  return (
    <div className="row">
      <Loader loading={loading} />
      <div className="com_modal">
        {/* Use Formik wrapper */}
        <Formik
          initialValues={{
            formData: {
              device_serial_no: details?.device_serial_no,
              iot_no: details?.iot_no,
              // fleet_no: details?.fleet_no,
              sos: details?.sos?.toString(),
              sos_text: details?.sos_text,
              network_operator: details?.network_operator,
            },
          }}
          onSubmit={handle}
          validationSchema={validationSchema}
        >
          {(formik) => (
            // Wrap your content in the Form component
            <Form className="form_default text_left">
              <div className="mb-3">
                <div className="row g-3">
                  <div className="">
                    <FormControl
                      control="InputLabel"
                      name={"label"}
                      type={"hidden"}
                      label={"IOT Device"}
                    />
                  </div>





                  <div className="col-lg-6">
                    <FormControl
                      control="InputLabel"
                      name={"formData.device_serial_no"}
                      type={"text"}
                      label={"Service Id"}
                      required={true}
                    />
                  </div>
                  
                  <div className="col-lg-6">
                    <FormControl
                      control="InputLabel"
                      name={"formData.network_operator"}
                      type={"text"}
                      label={"Characteristic Id"}
                      required={true}
                    />
                  </div>





                  <div className="col-lg-6">
                    <FormControl
                      control="InputLabel"
                      name={"formData.device_serial_no"}
                      type={"text"}
                      label={"Device Serial No."}
                    />
                  </div>
                  <div className="col-lg-6">
                    <FormControl
                      control="InputLabel"
                      name={"formData.network_operator"}
                      type={"text"}
                      label={"Network Opertaor/SIM Name"}
                      required={true}
                    />
                  </div>
                  <div className="col-lg-6">
                    <FormControl
                      control="InputLabel"
                      name={"formData.iot_no"}
                      type={"text"}
                      label={"IMEI Number"}
                      required={true}
                    />
                  </div>
                  <div className="col-lg-6">
                    <FormControl
                      control="InputLabel"
                      name={"formData.fleet_no"}
                      type={"number"}
                      label={"Vehicle"}
                    // required={true}
                    />
                  </div>
                  <div></div>
                  <div className="col-lg-6">
                    <FormControl
                      control="InputLabel"
                      name={"label"}
                      type={"hidden"}
                      label={"SOS"}
                    />
                  </div>
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6">
                    <FormControl
                      control="InputLabel"
                      name={"label"}
                      type={"hidden"}
                      label={"Integrate SOS"}
                    />
                  </div>
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6">
                    <FormControl
                      control="Radio"
                      name={"formData.sos"}
                      label={"no"}
                      value={"false"}
                    />
                  </div>
                  <div className="col-lg-6">
                    <FormControl
                      control="Radio"
                      name={"formData.sos"}
                      label={"Yes"}
                      value={"yes"}
                    />
                  </div>
                  <div className="col-lg-12">
                    <FormControl
                      control="InputLabel"
                      name={"formData.sos_text"}
                      type={"text"}
                      label={"SOS Notification Text"}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="col-lg-2" onClick={prevStep}>
                  <Button text="Back" type="button" isCancel={true} />
                </div>
                <div className="col-lg-9 d-flex justify-content-end ">
                  <div className="col-lg-2 mx-5" onClick={closeModal}>
                    <Button text="Cancel" type="button" isCancel={true} />
                  </div>

                  <div className="col-lg-2">
                    <Button text="Next" type="submit" />
                  </div>
                </div>
              </div>
              <div>
                <p>all asterisk (*) field are required</p>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default SosDetails;