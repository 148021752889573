import { createContext, useContext, useState } from "react";
import { Ability, AbilityBuilder } from "@casl/ability";
import routes from "routes";
import { jwtDecode } from "jwt-decode";

export function createAppAbility(isAdmin, perMission) {

  const { can, rules } = new AbilityBuilder();
  
  function isJSONString(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  }

  var perMissionArray = isJSONString(perMission)
    ? JSON.parse(perMission)
    : perMission;
  




  //   var token= localStorage.getItem('_authData');

  //   const decoded = jwtDecode(token);


  //   const permissionnew = [{
  //     adminId:decoded.adminId,
  //     id:"hsegdfkjefhjkswhelwaefli",
  //     name:"Channel_Partners"
  //   },
  //   {
  //     adminId:decoded.adminId,
  //     id:"hsegdfkjefhjkswhelwaefli",
  //     name:"Fleet_Status"
  //   },
  // ]


  //   if(decoded.adminId === "890d1c1c-4ffc-11ef-8175-50c2e89950e3"){
  //     perMissionArray = permissionnew
  //   }


  const moduleIdArray = perMissionArray?.map((item) => item.name);
  if (routes?.length > 0 && moduleIdArray?.length > 0) {

    can("view", "Dashboard")

    
    routes.forEach((route) => {
      if (moduleIdArray.includes(route.check)) {
        const matchingPermission = perMissionArray?.find(
          (permission) => permission.name == route.check
        );

     
        if (matchingPermission) {

          if (matchingPermission) {
            can("view", route.check)
          }


        }
      } 
    });
  }



  return new Ability(rules);
}

export const AbilityContext = createContext();

export function useAbility() {
  return useContext(AbilityContext);
}