import React, { useContext, useEffect, useMemo, useState } from 'react';
import FormControl from "components/controls/FormControl";
import CustomTable from "components/Table"
import Button from "components/buttons/ADD";
import EditDelete from "components/buttons/EditDelete";
import { ModalContext } from 'utils/Contexts/modalContext';
import CreateUser from 'components/modals/CreateUser';
import EditUser from 'components/modals/EditUser';
import CoomonDelete from 'components/modals/CoomonDelete';
import Verification from 'assets/img/dashboards/check_circle.svg';
import error from 'assets/img/dashboards/error.svg';
import Loader from 'components/loader';
import { DELETEDOCKYARDAPI, VIEWUSEREAPI, VIEWCREATECHANNELPARTNERAPI } from 'utils/Services';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Viewbutton } from 'components/Viewbutton';

function Fleetstats() {
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const [loading, setLoading] = useState(false)
    const [allData, setallData] = useState([])
    const [options, setOptions] = useState([])
    const [filteredData, setFilteredData] = useState([]);
    const [selectedView, setSelectedView] = useState('Daily');

    const navigate = useNavigate();
    const handleViewChange = (view) => {
        setSelectedView(view);
    };

    const FeedsColumns = useMemo(
        () => [
            {
                Header: "Sno.",
                accessor: (row, index) => {
                    return (
                        <>
                            <span className='text-sm'>{index + 1}</span>
                        </>
                    );
                }
            },

            {
                Header: "Date", 
                accessor: (Customer) => {
                    if (Customer?.channel_partner == 1) {
                        return "Other";
                    } else if (Customer?.channel_partner == 2) {
                        return "Zeway Rider";
                    } else {
                        return "Channel Partner"; 
                    }
                }
            },

            {
                Header: "Ride Duration",
                accessor: (Customer) => {
                    if (Customer?.channel_partner == 1) {
                        return "Other";
                    } else if (Customer?.channel_partner == 2) {
                        return "Zeway Rider";
                    } else {
                        return "Channel Partner"; 
                    }
                }
            },

            {
                Header: "Idle Duration",
                accessor: (Customer) => {
                    if (Customer?.channel_partner == 1) {
                        return "Other";
                    } else if (Customer?.channel_partner == 2) {
                        return "Zeway Rider";
                    } else {
                        return "Channel Partner"; 
                    }
                }
            },

            {
                Header: "Distance Driven(in Km)", 
                accessor: (Customer) => {
                    // if (Customer?.channel_partner == 1) {
                    //     return "Other";
                    // } else if (Customer?.channel_partner == 2) {
                    //     return "Zeway Rider";
                    // } else {
                    return "5.2";
                    // }
                }
            }

        ],
        [options?.length > 0]
    );
    const FeedsColumnsweak = useMemo(
        () => [
            {
                Header: "Sno.",
                accessor: (row, index) => {
                    return (
                        <>
                            <span className='text-sm'>{index + 1}</span>
                        </>
                    );
                }
            },
            {
                Header: "Week", 
                accessor: (Customer) => {
                    if (Customer?.channel_partner == 1) {
                        return "Other";
                    } else if (Customer?.channel_partner == 2) {
                        return "Zeway Rider";
                    } else {
                        return "Channel Partner"; 
                    }
                }
            },
            {
                Header: "Date", 
                accessor: (Customer) => {
                    if (Customer?.channel_partner == 1) {
                        return "Other";
                    } else if (Customer?.channel_partner == 2) {
                        return "Zeway Rider";
                    } else {
                        return "Channel Partner"; 
                    }
                }
            },

            {
                Header: "Ride Duration",
                accessor: (Customer) => {
                    if (Customer?.channel_partner == 1) {
                        return "Other";
                    } else if (Customer?.channel_partner == 2) {
                        return "Zeway Rider";
                    } else {
                        return "Channel Partner"; 
                    }
                }
            },

            {
                Header: "Idle Duration",
                accessor: (Customer) => {
                    if (Customer?.channel_partner == 1) {
                        return "Other";
                    } else if (Customer?.channel_partner == 2) {
                        return "Zeway Rider";
                    } else {
                        return "Channel Partner"; 
                    }
                }
            },

            {
                Header: "Distance Driven(in Km)", 
                accessor: (Customer) => {
                    // if (Customer?.channel_partner == 1) {
                    //     return "Other";
                    // } else if (Customer?.channel_partner == 2) {
                    //     return "Zeway Rider";
                    // } else {
                    return "5.2";
                    // }
                }
            }

        ],
        [options?.length > 0]
    );
    const FeedsColumnsmonth = useMemo(
        () => [
            {
                Header: "Sno.",
                accessor: (row, index) => {
                    return (
                        <>
                            <span className='text-sm'>{index + 1}</span>
                        </>
                    );
                }
            },

            {
                Header: "Month", 
                accessor: (Customer) => {
                    if (Customer?.channel_partner == 1) {
                        return "Other";
                    } else if (Customer?.channel_partner == 2) {
                        return "Zeway Rider";
                    } else {
                        return "Channel Partner"; 
                    }
                }
            },

            {
                Header: "Ride Duration",
                accessor: (Customer) => {
                    if (Customer?.channel_partner == 1) {
                        return "Other";
                    } else if (Customer?.channel_partner == 2) {
                        return "Zeway Rider";
                    } else {
                        return "Channel Partner"; 
                    }
                }
            },

            {
                Header: "Idle Duration",
                accessor: (Customer) => {
                    if (Customer?.channel_partner == 1) {
                        return "Other";
                    } else if (Customer?.channel_partner == 2) {
                        return "Zeway Rider";
                    } else {
                        return "Channel Partner"; 
                    }
                }
            },

            {
                Header: "Distance Driven(in Km)", 
                accessor: (Customer) => {
                    // if (Customer?.channel_partner == 1) {
                    //     return "Other";
                    // } else if (Customer?.channel_partner == 2) {
                    //     return "Zeway Rider";
                    // } else {
                    return "5.2";
                    // }
                }
            }

        ],
        [options?.length > 0]
    );


    const fecthData = async () => {
        try {
            setLoading(true)
            const result = await VIEWUSEREAPI();
            if (result?.res?.status == 200) {
                setallData(result?.res?.data)
                setLoading(false)
            }
            else {
                setLoading(false)
            }

        }
        catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    useEffect(() => {
        setFilteredData(allData);
    }, [allData]);


    useEffect(() => {
        if (options && options.length > 0) {
            fecthData()

        }

    }, [options]);
    useEffect(() => {

        fetchChannel()
    }, []);


    const handleDelete = async (data) => {
        setLoading(true)
        try {
            const result = await DELETEDOCKYARDAPI(data);

            if (result?.res?.status == 200) {
                setLoading(false)
                toast.success(result?.res?.message)
                fecthData(); closeModal();
            }
        }
        catch (err) {
            toast.error(err?.message || err)
            setLoading(false)
            closeModal();
        }


    }
    const fetchChannel = async () => {
        setLoading(true);
        try {
            const result = await VIEWCREATECHANNELPARTNERAPI();

            if (result?.res?.status === 200) {
                const data = result?.res?.data?.map((res) => ({
                    id: res?.id,
                    label: res?.channel_partner_name,
                    value: res?.channel_partner_name
                }));

                setOptions(data);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const handle = (data, info) => {
        // const CreateDocumnet = <CreateUser closeModal={closeModal} reload={fecthData} />;
        // const EditDocumnet = <EditUser closeModal={closeModal} data={info} reload={fecthData} />;
        // const DelteDocumnet = <CoomonDelete closeModal={closeModal} data={info} handle={handleDelete} reload={fecthData} />
        // switch (data) {
        //     case "create":
        //         handleModalData(CreateDocumnet, "md");
        //         break;
        //     case "edit":
        //         handleModalData(EditDocumnet, "md");
        //         break;
        //     case "delete":
        //         handleModalData(DelteDocumnet, "md");
        //         break;

        // }

    }

    const handleDetails = (id) => {

        // navigate(`/rider_details/${id}`)
    }

    return (
        <div className="container-fluid pb-5">
            <div className='row'>
                <Loader loading={loading} />
                <div className='my-3'>
                    <div className='d-flex boder_box'>
                        <div className='col-lg-6' style={{ padding: "24px 32px", borderRight: " 1px solid rgba(155, 155, 155, 1)" }}>
                            <div className=''>
                                <div className='vehicalno'><div>Vehical ID: </div> <div>45325573ADS</div></div>
                                <div className='vehicalno'><div>Zeeway Registration Number: </div> <div>AR51HR4727</div></div>
                                <div className='vehicalno'><div>Rider Name: </div> <div>Ramesh Sharma</div></div>

                            </div>
                        </div>
                        <div className='col-lg-6' style={{ padding: "24px 32px" }}>
                            <div className=''>
                                <div className='vehicalno'><div>Location </div> <div>Noida Sec-16</div></div>
                                <div className='vehicalno'><div>Contact Number:</div> <div>9786349687</div></div>
                                <div className='vehicalno'><div>Live status:</div> <div>Running</div></div>

                            </div>
                        </div>

                    </div>
                    {/* <div className='col-lg-4'>
                        <FormControl control={"Search"} />


                    </div>
                    <div onClick={() => handle("create")}>
                        <Button text="Generate New Report" />
                    </div> */}
                </div>

                <div className='my-3'>
                    <div className='mb-2'>Time Period</div>
                    <Viewbutton size="md" onSelectView={handleViewChange} />
                </div>

                {filteredData.length > 0 && (
                    <div className='default_table'>

                        {selectedView === 'Daily' && <CustomTable data={filteredData} columns={FeedsColumns} />}
                        {selectedView === 'Weekly' && <CustomTable data={filteredData} columns={FeedsColumnsweak} />}
                        {selectedView === 'Monthly' && <CustomTable data={filteredData} columns={FeedsColumnsmonth} />}


                    </div>
                )}
            </div>
        </div>
    )
}




export default Fleetstats