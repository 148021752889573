import React, { useContext, useEffect, useMemo, useState } from 'react';


import { ModalContext } from 'utils/Contexts/modalContext';
import CreatChannelPartner from 'components/modals/CreatChannelPartner';
import EditChannelPartner from 'components/modals/EditChannelPartner';
import CoomonDelete from 'components/modals/CoomonDelete';
import Loader from 'components/loader';
import { KYCDOCAPI, DOCUMENTSVIEWAPI } from 'utils/Services';
import { toast } from 'react-toastify';
import Button from 'components/buttons/BoderButton';
import { saveAs } from 'file-saver';
function Kyc({ data }) {
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const [loading, setLoading] = useState(false)
    const [allData, setallData] = useState([])

    const [voter, setVoter] = useState({})
    const [aadhar, setAadhar] = useState({})
    const [card, setCard] = useState({})



    const FeedsColumns = useMemo(
        () => [



            {
                Header: "x", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.zip_code || "" // Use conditional rendering
            },


        ],
        []
    );
    const fecthData = async () => {
        try {
            setLoading(true)
            const result = await KYCDOCAPI(data);

            if (result?.res?.status == 200) {
                setallData(result?.res?.data)
    
                const filteredResults = result?.res?.data?.document?.filter((res) => res.type == "Aadhaar") ?? [];

                setAadhar(filteredResults[0])
                setLoading(false)
            }
            else {
                setLoading(false)
            }

        }
        catch (err) {
            console.log(err)
            setLoading(false)
        }

    }
    useEffect(() => {
        fecthData()
    }, [data]);

    // const handleDownload = async (result) => {
    //     const cus_data =
    //     {
    //         "customerId": data,
    //         "type": result
    //     }

    //     try {
    //         setLoading(true)
    //         const result_data = await DOCUMENTSVIEWAPI(cus_data)


    //         if (result_data?.res?.status == 200) {
    //             if(result)
    //             {
    //                 saveAs(result_data?.res?.data.documentUrl, `${result}.jpg`)
    //             }
    //             else{
    //                 saveAs(result_data?.res?.data.documentUrl, `${'profile'}.jpg`)
    //             }
          
    //             toast.success(`${result} Downloaded Successfully`)
    //             setLoading(false)
    //         }
    //         else {
    //             setLoading(false);
    //             toast.error(`${result} Not  Downloaded`)
    //         }

    //     }
    //     catch (err) {

    //         setLoading(false);
    //         toast.error(err.message)
    //     }

    // }

    const handleDownload = async (result) => {
        const cus_data = {
            "customerId": data,
            "type": result
        };
    
        try {
            setLoading(true);
            const result_data = await DOCUMENTSVIEWAPI(cus_data);
    
            if (result_data?.res?.status == 200) {
                const documentUrl = result_data?.res?.data.documentUrl;
                const response = await fetch(documentUrl); // Fetch the file from the URL
                const blob = await response.blob(); // Convert to Blob
                const contentType = response.headers.get('content-type'); // Get the content type (e.g., application/pdf, image/jpeg)
                
                // Determine the file extension based on content type
                const extension = contentType.includes('pdf') ? 'pdf' : 'jpg';  
                const fileName = result ? `${result}.${extension}` : `profile.${extension}`;
                
                saveAs(blob, fileName); // Save the file using Blob
    
                toast.success(`${result} Downloaded Successfully`);
                setLoading(false);
            } else {
                setLoading(false);
                toast.error(`${result} Not Downloaded`);
            }
        } catch (err) {
            setLoading(false);
            toast.error(err.message);
        }
    };
    
    

    function maskAadhaarNumber(number) {
        if (number) {
            return number.slice(0, -4).replace(/./g, '*') + number.slice(-4);
        }
        return "NA";
    }

    return (
        <div className="container-fluid pb-5">
            <div className='row'>
                <Loader loading={loading} />
                <div className='my-5'>
                <div className='details_tile'>
                        <div className='d-flex justify-content-between'>
                            <div className='col-lg-6 text-center'>
                            Profile Image
                            </div>
                            <div className='col-lg-6 text-center'>
                                {
                                    aadhar?.document_no ? (<div className='d-flex align-items-center justify-content-center'>
                                        <div className='col-lg-2' onClick={() => handleDownload('')}>
                                            <Button text="View" type="button" />
                                        </div>
                                        <a className='col-lg-2 mx-5' onClick={() => handleDownload('')}>
                                            <Button text="Download" type="button" />
                                        </a>
                                    </div>) : "NA"
                                }

                            </div>

                        </div>



                    </div>
                    <div className='details_tile'>
                        <div className='d-flex justify-content-between'>
                            <div className='col-lg-6 text-center'>
                                Aadhaar Number
                            </div>
                            <div className='col-lg-6 text-center'>
                                {
                                    aadhar?.document_no ? maskAadhaarNumber(aadhar?.document_no)
                                        : "NA"
                                }

                            </div>

                        </div>



                    </div>
                    <div className='details_tile'>
                        <div className='d-flex justify-content-between'>
                            <div className='col-lg-6 text-center'>
                                Aadhaar  Image
                            </div>
                            <div className='col-lg-6 text-center'>
                                {
                                    aadhar?.document_no ? (<div className='d-flex align-items-center justify-content-center'>
                                        <div className='col-lg-2' onClick={() => handleDownload('Aadhaar')}>
                                            <Button text="View" type="button" />
                                        </div>
                                        <a className='col-lg-2 mx-5' onClick={() => handleDownload('Aadhaar')}>
                                            <Button text="Download" type="button" />
                                        </a>
                                    </div>) : "NA"
                                }

                            </div>

                        </div>



                    </div>

                    <div className='details_tile'>
                        <div className='d-flex justify-content-between'>
                            <div className='col-lg-6 text-center'>
                                Driving License Front Image
                            </div>
                            <div className='col-lg-6 text-center'>

                                {
                                    aadhar?.document_no ? (<div className='d-flex align-items-center justify-content-center'>
                                        <div className='col-lg-2' onClick={() => handleDownload('DrivingLicence')}>
                                            <Button text="View" type="button" />
                                        </div>
                                        <div className='col-lg-2 mx-5' onClick={() => handleDownload('DrivingLicence')}>
                                            <Button text="Download" type="button" />
                                        </div>
                                    </div>) : "NA"
                                }

                            </div>

                        </div>



                    </div>
                    <div className='details_tile'>
                        <div className='d-flex justify-content-between'>
                            <div className='col-lg-6 text-center'>
                                ID Card Front Image
                            </div>
                            <div className='col-lg-6 text-center'>
                                {
                                    aadhar?.document_no ? (<div className='d-flex align-items-center justify-content-center'>
                                        <div className='col-lg-2' onClick={() => handleDownload('Voter ID')}>
                                            <Button text="View" type="button" />
                                        </div>
                                        <div className='col-lg-2 mx-5' onClick={() => handleDownload('Voter ID')}>
                                            <Button text="Download" type="button" />
                                        </div>
                                    </div>) : "NA"
                                }


                            </div>

                        </div>



                    </div>
                    <div className='details_tile'>
                        <div className='d-flex justify-content-between'>
                            <div className='col-lg-6 text-center'>
                                Pan Card Image
                            </div>
                            <div className='col-lg-6 text-center'>
                                {
                                    aadhar?.document_no ? (<div className='d-flex align-items-center justify-content-center'>
                                        <div className='col-lg-2' onClick={() => handleDownload('Pan Card')}>
                                            <Button text="View" type="button" />
                                        </div>
                                        <div className='col-lg-2 mx-5' onClick={() => handleDownload('Pan Card')}>
                                            <Button text="Download" type="button" />
                                        </div>
                                    </div>) : "NA"
                                }


                            </div>

                        </div>



                    </div>
                </div>





            </div>
        </div>
    )
}

export default Kyc