import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';  // Import Formik and Form
import FormControl from 'components/controls/FormControl';
import Loader from "components/loader"
import Button from 'components/buttons/ADD';
import {
    VIEWCREATECHANNELPARTNERAPI,
    UPDATEADMINMANAGERPI, EDITADMINMANAGERPI
} from 'utils/Services';
import * as Yup from 'yup';
import { toast } from 'react-toastify';



function EditAdmin({ closeModal, data, reload }) {
    const [selectAllnotification, setSelectAllnotification] = useState(false);
    const [selectAllaccess, setSelectAllaccess] = useState(false);
    const [statusOptions, setStatusOptions] = useState([
        {
            id: true,
            label: 'Active',
            value: true
        },
        {
            id: false,
            label: 'Inactive',
            value: false
        }
    ]);

    const [roleOptions, setRoleOptions] = useState([
        {
            id: 1,
            label: 'Zeway Super Admin',
            value: 'super_admin'
        },
        {
            id: 2,
            label: 'Admin (Channel Partner)',
            value: 'admin'
        },
        {
            id: 3,
            label: 'Manager (Channel Partner)',
            value: 'manager'
        }
    ]);

    const [partnerOptions, setPartnerOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [allData, setAllData] = useState({})


    const validationSchema = Yup.object().shape({
        formData: Yup.object().shape({
            admin_name: Yup.string().required('Admin name is required.'),
            role: Yup.string().required('Role is required.'),
            channelId: Yup.string().required('Channel Partner ID is required.'),
            contact_no: Yup.string()
            .required('Contact number is required.')
            .matches(/^\d{10}$/, 'Contact number must be exactly 10 digits.'),
        }),
    });
    const handle = async (values) => {

        setLoading(true)
        try {
            const notificationData = Object.entries(values.formData.notification[0])
                .filter(([key, value]) => value)
                .map(([key]) => ({ name: key }));
            const accessData = Object.entries(values.formData.access[0])
                .filter(([key, value]) => value)
                .map(([key]) => ({ name: key }));
            const admin_data = { ...values.formData }
            const _data = {
                "admin": admin_data, 'access': accessData, 'notification': notificationData
            }
            const result = await UPDATEADMINMANAGERPI(data, _data);
            if (result?.status == 201) {
                toast.success(result?.message)
                setLoading(false)
                { reload && reload() }
                closeModal()
            }
            else {
                toast.error(result?.res?.message)
                setLoading(false)
            }



        }
        catch (err) {
            setLoading(false)
            toast.error(err?.message)
        }
    }

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await VIEWCREATECHANNELPARTNERAPI();
            if (result?.res?.status == 200) {

                const filteredData = result?.res?.data?.filter(item => item?.is_active == true)?.map(res => ({
                    id: res?.id,
                    label: res?.channel_partner_name,
                    value: res?.channel_partner_name,
                }));

                setPartnerOptions(filteredData)


                setLoading(false)
            }
        }
        catch (err) {
            setLoading(false);
            toast.error(err)

        }




    }

    const fetchAdminData = async () => {
        try {
            setLoading(true)
            const result = await EDITADMINMANAGERPI(data);

            if (result?.res?.status == 200) {


                if (result?.res?.data?.access && result?.res?.data?.access?.length == 9) {
                    setSelectAllaccess(true)
                }
                if (result?.res?.data?.notification && result?.res?.data?.notification?.length == 6) {
                    setSelectAllnotification(true)
                }
                setAllData(result?.res?.data)
                setLoading(false)

            }
        }
        catch (err) {
            setLoading(false);
            toast.error(err)

        }
    }
    useEffect(() => {

        fetchAdminData();
        fetchData();
    }, [])
   
    return (
        <div className='row'>
            <Loader loading={loading} />

            {/* Use Formik wrapper */}

            {
                allData ? (<div className='com_modal'>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            formData: {

                                admin_name: allData?.admin?.admin_name,
                                email: allData?.admin?.email,
                                contact_no: allData?.admin?.contact_no,
                                role: allData?.admin?.role ? allData?.admin?.role : 0,
                                channelId: allData?.admin?.channelId ? allData?.admin?.channelId : 0,
                                is_active: allData?.admin?.is_active || false,
                                access: [
                                    {
                                        Admins_Managers: allData?.access?.some(item => item.name == "Admins_Managers"),
                                        Channel_Partners: allData?.access?.some(item => item.name == "Channel_Partners"),
                                        Plans: allData?.access?.some(item => item.name == "Plans"),
                                        Fleet_Management: allData?.access?.some(item => item.name == "Fleet_Management"),
                                        Dockyards: allData?.access?.some(item => item.name == "Dockyards"),
                                        Users: allData?.access?.some(item => item.name == "Users"),
                                        Maintenance: allData?.access?.some(item => item.name == "Maintenance"),
                                        Reports: allData?.access?.some(item => item.name == "Reports"),
                                        Transaction: allData?.access?.some(item => item.name == "Transaction"),

                                        FleetStatus: allData?.access?.some(item => item.name == "FleetStatus"),
                                        FleetReports: allData?.access?.some(item => item.name == "FleetReports"),
                                        FleetBilling: allData?.access?.some(item => item.name == "FleetBilling"),
                                    }
                                ],
                                notification: [{
                                    New_User_Registration: allData?.notification?.some(item => item.name == "New_User_Registration"),
                                    Subscription_Renewal: allData?.notification?.some(item => item.name == "Subscription_Renewal"),
                                    Low_Balance_Alert: allData?.notification?.some(item => item.name == "Low_Balance_Alert"),
                                    Vehicle_Maintenance_Reminder: allData?.notification?.some(item => item.name == "Vehicle_Maintenance_Reminder"),
                                    Critical_SOC_Voltage_Alerts: allData?.notification?.some(item => item.name == "Critical_SOC_Voltage_Alerts"),
                                    New_Facility_Created: allData?.notification?.some(item => item.name == "New_Facility_Created"),
                                }]
                            }
                        }}

                        onSubmit={handle}
                        validationSchema={validationSchema}

                    >
                        {formik => {

                            return (
                                <Form className='form_default text_left'>
                                    <div className='mb-3'>
                                        <FormControl control="FormHead" title="Update Admin  Manager" />
                                    </div>

                                    <div className='mb-3'>
                                        <div className='row g-3'>
                                            <div className='col-lg-6'>

                                                <FormControl

                                                    name={"formData.role"}
                                                    label={"Role"}
                                                    options={roleOptions}
                                                    prevalue={formik?.values?.formData?.role}
                                                    control="SelectBox"
                                                    required={true}






                                                />

                                            </div>

                                            <div className='col-lg-6'>
                                            </div>
                                            <div className='col-lg-6'>

                                                <FormControl
                                                    control="SelectBox"
                                                    name={"formData.channelId"}
                                                    label={" Channel Partner"}
                                                    options={partnerOptions}
                                                    prevalue={formik?.values?.formData?.channelId}
                                                    required={true}

                                                />
                                            </div>

                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="InputLabel"
                                                    name={"formData.admin_name"}
                                                    type={"text"}
                                                    label={"Admin Name"}
                                                    value={formik?.values?.formData?.admin_name}
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="InputLabel"
                                                    name={"formData.contact_no"}
                                                    type={"text"}
                                                    label={"Contact No"}
                                                    disabled={true}
                                                    value={formik?.values?.formData?.contact_no}
                                                />
                                            </div>

                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="InputLabel"
                                                    name={"formData.email"}
                                                    type={"Email"}
                                                    label={"Email"}
                                                    disabled={true}
                                                    value={formik?.values?.formData?.email}
                                                />
                                            </div>

                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="SelectBox"
                                                    name={"formData.is_active"}
                                                    options={statusOptions}
                                                    label={"Status"}
                                                    prevalue={formik.values.formData.is_active}
                                                />
                                            </div>

                                            <div className='col-lg-6'></div>


                                            <div className='col-lg-6 my-5'>
                                                <div className='d-flex justify-content-between  align-item-start'>
                                                    <div>
                                                        <FormControl
                                                            control="InputLabel"
                                                            name={"formData.label"}
                                                            type="hidden"
                                                            label={"Notification Preferences"}

                                                        />
                                                    </div>
                                                    <div>
                                                        <FormControl
                                                            control="Checkbox"
                                                            name={"formData.all"}
                                                            label={"Select All"}
                                                            checked={selectAllnotification}
                                                            onChange={(e) => {
                                                                setSelectAllnotification(e.target.checked);
                                                                formik.setFieldValue("formData.notification[0].New_User_Registration", e.target.checked);
                                                                formik.setFieldValue("formData.notification[0].Subscription_Renewal", e.target.checked);
                                                                formik.setFieldValue("formData.notification[0].Low_Balance_Alert", e.target.checked);
                                                                formik.setFieldValue("formData.notification[0].Vehicle_Maintenance_Reminder", e.target.checked);
                                                                formik.setFieldValue("formData.notification[0].Critical_SOC_Voltage_Alerts", e.target.checked);
                                                                formik.setFieldValue("formData.notification[0].New_Facility_Created", e.target.checked);
                                                            }}
                                                        />
                                                    </div>

                                                    <div>

                                                    </div>

                                                </div>

                                                <div>
                                                    <FormControl
                                                        control="Checkbox"
                                                        name={"formData.notification[0].New_User_Registration"}
                                                        label={"New User Registration"}
                                                        checked={formik.values.formData.notification[0].New_User_Registration}
                                                        onChange={(e) => {
                                                            formik.setFieldValue("formData.notification[0].New_User_Registration", e.target.checked);
                                                            if (!e.target.checked) {
                                                                setSelectAllnotification(false); // Uncheck "Select All" if any checkbox is unchecked
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <FormControl
                                                        control="Checkbox"
                                                        name={"formData.notification[0].Subscription_Renewal"}
                                                        label={"Subscription Renewal"}
                                                        checked={formik.values.formData.notification[0].Subscription_Renewal}
                                                        onChange={(e) => {
                                                            formik.setFieldValue("formData.notification[0].Subscription_Renewal", e.target.checked);
                                                            if (!e.target.checked) {
                                                                setSelectAllnotification(false); // Uncheck "Select All" if any checkbox is unchecked
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <FormControl
                                                        control="Checkbox"
                                                        name={"formData.notification[0].Low_Balance_Alert"}
                                                        label={"Low Balance Alert"}
                                                        checked={formik.values.formData.notification[0].Low_Balance_Alert}
                                                        onChange={(e) => {
                                                            formik.setFieldValue("formData.notification[0].Low_Balance_Alert", e.target.checked);
                                                            if (!e.target.checked) {
                                                                setSelectAllnotification(false); // Uncheck "Select All" if any checkbox is unchecked
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <FormControl
                                                        control="Checkbox"
                                                        name={"formData.notification[0].Vehicle_Maintenance_Reminder"}
                                                        label={"Vehicle Maintenance Reminder"}
                                                        checked={formik.values.formData.notification[0].Vehicle_Maintenance_Reminder}
                                                        onChange={(e) => {
                                                            formik.setFieldValue("formData.notification[0].Vehicle_Maintenance_Reminder", e.target.checked);
                                                            if (!e.target.checked) {
                                                                setSelectAllnotification(false); // Uncheck "Select All" if any checkbox is unchecked
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <FormControl
                                                        control="Checkbox"
                                                        name={"formData.notification[0].Critical_SOC_Voltage_Alerts"}
                                                        label={"Critical SOC/Voltage Alerts"}
                                                        checked={formik.values.formData.notification[0].Critical_SOC_Voltage_Alerts}
                                                        onChange={(e) => {
                                                            formik.setFieldValue("formData.notification[0].Critical_SOC_Voltage_Alerts", e.target.checked);
                                                            if (!e.target.checked) {
                                                                setSelectAllnotification(false); // Uncheck "Select All" if any checkbox is unchecked
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <FormControl
                                                        control="Checkbox"
                                                        name={"formData.notification[0].New_Facility_Created"}
                                                        label={"New Facility Created"}
                                                        checked={formik.values.formData.notification[0].New_Facility_Created}
                                                        onChange={(e) => {
                                                            formik.setFieldValue("formData.notification[0].New_Facility_Created", e.target.checked);
                                                            if (!e.target.checked) {
                                                                setSelectAllnotification(false); // Uncheck "Select All" if any checkbox is unchecked
                                                            }
                                                        }}
                                                    />
                                                </div>

                                            </div>
                                            <div className='col-lg-6 my-5'>
                                                <div className='d-flex justify-content-between  align-item-start'>
                                                    <div>
                                                        <FormControl
                                                            control="InputLabel"
                                                            name={"formData.label"}
                                                            type="hidden"
                                                            label={"Access Preferences"}

                                                        />
                                                    </div>
                                                    <div>
                                                        <FormControl
                                                            control="Checkbox"
                                                            name={"formData.all"}
                                                            label={"Select All"}
                                                            checked={selectAllaccess}
                                                            onChange={(e) => {
                                                                setSelectAllaccess(e.target.checked);
                                                                formik.setFieldValue("formData.access[0].Admins_Managers", e.target.checked);
                                                                formik.setFieldValue("formData.access[0].Plans", e.target.checked);
                                                                formik.setFieldValue("formData.access[0].Fleet_Management", e.target.checked);
                                                                formik.setFieldValue("formData.access[0].Reports", e.target.checked);
                                                                formik.setFieldValue("formData.access[0].Channel_Partners", e.target.checked);
                                                                formik.setFieldValue("formData.access[0].Dockyards", e.target.checked);
                                                                formik.setFieldValue("formData.access[0].Maintenance", e.target.checked);
                                                                formik.setFieldValue("formData.access[0].Users", e.target.checked);
                                                                formik.setFieldValue("formData.access[0].Transaction", e.target.checked);

                                                                formik.setFieldValue("formData.access[0].FleetStatus", e.target.checked);
                                                                formik.setFieldValue("formData.access[0].FleetReports", e.target.checked);
                                                                formik.setFieldValue("formData.access[0].FleetBilling", e.target.checked);
                                                            }}
                                                        />
                                                    </div>

                                                    <div>

                                                    </div>

                                                </div>

                                                <div>
                                                    <FormControl
                                                        control="Checkbox"
                                                        name={"formData.access[0].Admins_Managers"}
                                                        label={"Admins Managers"}
                                                        checked={formik?.values?.formData?.access[0]?.Admins_Managers}
                                                        onChange={(e) => {
                                                            formik.setFieldValue("formData.access[0].Admins_Managers", e.target.checked);
                                                            if (!e.target.checked) {
                                                                setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <FormControl
                                                        control="Checkbox"
                                                        name={"formData.access[0].Channel_Partners"}
                                                        label={"Channel Partners"}
                                                        checked={formik.values.formData.access[0].Channel_Partners}
                                                        onChange={(e) => {
                                                            formik.setFieldValue("formData.access[0].Channel_Partners", e.target.checked);
                                                            if (!e.target.checked) {
                                                                setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                            }
                                                        }}
                                                    />
                                                </div>



                                                <div>
                                            <FormControl
                                                control="Checkbox"
                                                name={"formData.access[0].FleetStatus"}
                                                label={"FleetStatus (Channel Partner)"}
                                                checked={formik.values.formData.access[0].FleetStatus}
                                                onChange={(e) => {
                                                    formik.setFieldValue("formData.access[0].FleetStatus", e.target.checked);
                                                    if (!e.target.checked) {
                                                        setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                    }
                                                }}
                                            />
                                        </div>


                                        <div>
                                            <FormControl
                                                control="Checkbox"
                                                name={"formData.access[0].FleetReports"}
                                                label={"FleetReports (Channel Partner)"}
                                                checked={formik.values.formData.access[0].FleetReports}
                                                onChange={(e) => {
                                                    formik.setFieldValue("formData.access[0].FleetReports", e.target.checked);
                                                    if (!e.target.checked) {
                                                        setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                    }
                                                }}
                                            />
                                        </div>

                                        <div>
                                            <FormControl
                                                control="Checkbox"
                                                name={"formData.access[0].FleetBilling"}
                                                label={"FleetBilling (Channel Partner)"}
                                                checked={formik.values.formData.access[0].FleetBilling}
                                                onChange={(e) => {
                                                    formik.setFieldValue("formData.access[0].FleetBilling", e.target.checked);
                                                    if (!e.target.checked) {
                                                        setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                    }
                                                }}
                                            />
                                        </div>








                                                
                                                <div>
                                                    <FormControl
                                                        control="Checkbox"
                                                        name={"formData.access[0].Plans"}
                                                        label={"Plans"}
                                                        checked={formik.values.formData.access[0].Plans}
                                                        onChange={(e) => {
                                                            formik.setFieldValue("formData.access[0].Plans", e.target.checked);
                                                            if (!e.target.checked) {
                                                                setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <FormControl
                                                        control="Checkbox"
                                                        name={"formData.access[0].Fleet_Management"}
                                                        label={"Fleet Management"}
                                                        checked={formik.values.formData.access[0].Fleet_Management}
                                                        onChange={(e) => {
                                                            formik.setFieldValue("formData.access[0].Fleet_Management", e.target.checked);
                                                            if (!e.target.checked) {
                                                                setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <FormControl
                                                        control="Checkbox"
                                                        name={"formData.access[0].Dockyards"}
                                                        label={"Dockyards"}
                                                        checked={formik.values.formData.access[0].Dockyards}
                                                        onChange={(e) => {
                                                            formik.setFieldValue("formData.access[0].Dockyards", e.target.checked);
                                                            if (!e.target.checked) {
                                                                setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <FormControl
                                                        control="Checkbox"
                                                        name={"formData.access[0].Users"}
                                                        label={"Users"}
                                                        checked={formik.values.formData.access[0].Users}
                                                        onChange={(e) => {
                                                            formik.setFieldValue("formData.access[0].Users", e.target.checked);
                                                            if (!e.target.checked) {
                                                                setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <FormControl
                                                        control="Checkbox"
                                                        name={"formData.access[0].Maintenance"}
                                                        label={"Maintenance"}
                                                        checked={formik.values.formData.access[0].Maintenance}
                                                        onChange={(e) => {
                                                            formik.setFieldValue("formData.access[0].Maintenance", e.target.checked);
                                                            if (!e.target.checked) {
                                                                setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                <div>
                                                    <FormControl
                                                        control="Checkbox"
                                                        name={"formData.access[0].Reports"}
                                                        label={"Reports"}
                                                        checked={formik.values.formData.access[0].Reports}
                                                        onChange={(e) => {
                                                            formik.setFieldValue("formData.access[0].Reports", e.target.checked);
                                                            if (!e.target.checked) {
                                                                setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <FormControl
                                                        control="Checkbox"
                                                        name={"formData.access[0].Transaction"}
                                                        label={"Transaction"}
                                                        checked={formik.values.formData.access[0].Transaction}
                                                        onChange={(e) => {
                                                            formik.setFieldValue("formData.access[0].Transaction", e.target.checked);
                                                            if (!e.target.checked) {
                                                                setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                            }
                                                        }}
                                                    />
                                                </div>


                                            </div>



                                        </div>

                                    </div>

                                    <div className='d-flex justify-content-end'>
                                        <div className='col-lg-2 mx-5' onClick={closeModal}>
                                            <Button text="Cancel" type="button" isCancel={true} />
                                        </div>

                                        <div className='col-lg-2'>
                                            <Button text="Save" type="submit" />
                                        </div>

                                    </div>

                                    <div>
                                        <p>
                                            all asterisk (*) field are required
                                        </p>
                                    </div>
                                </Form>
                            )
                        }}

                    </Formik>
                </div>) : (<Loader loading={true} />)
            }


        </div >
    );
}

export default EditAdmin;
